.responsive-section span {
    border-right: .05em solid;
    animation: caret 1s steps(1) infinite;
  }
  
  @keyframes caret {
    50% {
      border-color: transparent;
    }
  }

#about-me {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#about-me-image {
    height: 300px;
    background-image: url('/assets/images/GabyCabrera.jpg');
    background-position: center;
    background-size: cover;
    padding: 2rem;
}

p {
    padding: 1rem;
}

a {
  color: unset;
}

h1 {
  font-size: 2rem;
  font-family: 'Prata', serif;
}

button {
  background-color: unset;
  border: none;
}

@media only screen and (min-width: 992px) {
    #about-me-image {
        height: unset;
    }
}