.App-logo {
    height: 5vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

.navbar-collapse {
  text-align: center;
}

#navbarNavAltMarkup {
  justify-content: flex-end;
}

.nav-link > .active {
  border-bottom: 1px solid #333;
}
.navbar-brand {
  font-family: 'Prata', serif;
  font-size: 1.5rem;
}

.navbar-nav a {
  padding: 0.5rem;
  color: rgba(255,255,255,.5);
}

.navbar-nav a:hover {
  color: white;
  text-decoration: none;
}
