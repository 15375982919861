#root {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.bg-dark {
    background-color: #1D140C!important;
}

.nav-link {
    cursor: pointer;
}

main {
    flex: 1 0 auto;
}

.container-fluid {
    padding: 0;
}

.page-section {
    padding: 0;
}

.responsive-section {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.sub-section {
    flex: 1 1 auto;
}

@media only screen and (min-width: 992px) {
    .responsive-section {
        display: flex;
        flex-direction: row;
    }

    .sub-section {
        flex: 1 1 100%;
    }
}